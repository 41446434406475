// core
import React, { useState, useEffect } from "react";
// modal
import Modal from "react-modal";

export default function ModalWrap({ visible, content }) {
  const [modal, set_modal] = useState({
    visible: visible,
    content: content,
  });
  useEffect(() => {
    set_modal({
      visible: visible,
      content: content,
    });
  }, [visible, content]);
  if (visible === false) return <></>;
  if (modal.visible === false) return <></>;
  return (
    <>
      <Modal
        parentSelector={() => document.querySelector("#root")}
        portalClassName="modal"
        className="modal-content"
        overlayClassName="modal-overlay"
        onRequestClose={() => set_modal({ visible: false, content: "" })}
        shouldCloseOnOverlayClick={true}
        isOpen={modal.visible}
      >
        <a
          href="/"
          className="modal-content-closer"
          onClick={(evt) => {
            evt.preventDefault();
            set_modal({
              visible: false,
              content: "",
            });
          }}
        ></a>
        <div className="modal-content-body">{modal.content}</div>
      </Modal>
    </>
  );
}

import { useRef, useEffect } from "react";

export default function FileView({ title, target }) {
  const link_element = useRef();

  useEffect(() => {
    // Use setTimeout to update the message after 2000 milliseconds (2 seconds)
    const timeoutId = setTimeout(() => {
      link_element.current.click();
    }, 2000);

    // Cleanup function to clear the timeout if the component unmounts
    return () => clearTimeout(timeoutId);
  }, []); // Empty dependency array ensures the effect runs only once

  return (
    <div className="col-md-9 page">
      <h3>{title}</h3>
      <div>
        <a ref={link_element} href={target}>
          click here if the download does not start automatically.
        </a>
      </div>
    </div>
  );
}

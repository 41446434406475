// core

export default function SearchBox({ on_fire, on_change, value }) {
  return (
    <>
      <div className="field" style={{ flex: 1 }}>
        <input
          type="text"
          className="form-control"
          id="sarchtext"
          placeholder="Search Library Asset"
          value={value}
          onChange={(evt) => on_change(evt.target.value)}
          onKeyDown={(evt) => {
            if (evt.key === "Enter") {
              evt.preventDefault();
              on_fire(value);
            }
          }}
        />
      </div>
      <button
        type="submit"
        className="button-wk1"
        onClick={(evt) => {
          evt.preventDefault();
          on_fire(value);
        }}
      >
        Search
      </button>
    </>
  );
}

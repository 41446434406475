import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import RouteChangeHandler from "./RouteChangeHandler";

// carousel css
// css
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "*",
    element: (
      <>
        <RouteChangeHandler />
        <App request={window.location.href} />
      </>
    ),
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<RouterProvider router={router} />);

// helpers
import { clean_raw_html } from "helpers/helpers";

function Short({ url, title, desc }) {
  return (
        <a className="pill pill-blue" data-tooltip={desc} href={clean_raw_html(url)}>{title} <i className="icon icon-small icon-more icon-neutral-1-light"></i></a>
  );
}

export default function ShortCuts({ data }) {
  if (data === null) return <></>;
  if (!Array.isArray(data)) return <></>;

  const shortcuts = [];
  data.forEach((item, idx) => {

    shortcuts.push(
      <Short
        key={idx}
        url={item["@id"]}
        title={item.title}
        desc={item.description}
      />,
    );
  });
  return (
    <>
      <div className="container">
        <h3>Shortcuts for your profile</h3>
        <br />
        <div className="container flex ">{shortcuts}</div>
      </div>
    </>
  );
}

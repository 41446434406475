// conf
import { conf, queries } from "conf/plone";
// core
import React, { useState } from "react";
// hooks
import { useFetch } from "usehooks-ts";
// app components
import Spinner from "components/spinner";
import LibraryItemSmall from "./library_item";
import Column from "./column";
import LibraryTags from "components/homepage/library_tags";
import PageItem from "components/facet/item_page";
import ErrorMessage from "components/error_message";
// modal
import ModalWrap from "components/modalwrap";

export default function LatestAssets({ home_url }) {
  // state
  const [modal, set_modal] = useState({
    visible: false,
    content: "",
  });
  // cb
  const _handle_click = (evt) => {
    // mange click on href
    if (
      evt.target.tagName.toLowerCase() === "a" &&
      evt.target.classList.contains("react-managed")
    ) {
      evt.stopPropagation();
      evt.preventDefault();
      const ref = evt.target.href;
      set_modal({
        visible: true,
        content: <PageItem url={ref} />,
      });
    }
  };
  // deep cloning
  const opts = JSON.parse(JSON.stringify(conf.ajax_configuration_object));
  opts.method = "POST";
  opts.body = JSON.stringify(queries.latest_assets);

  const { data, error } = useFetch(conf.endpoints.latest_assets, opts);
  if (error) return <ErrorMessage error={error} />;
  if (!data) return <Spinner />;

  const assets = [];
  data.items.forEach((item, idx) => {
    assets.push(
      <div className="container"><LibraryItemSmall key={idx} item={item} onClick={_handle_click} /></div>,
    );
  });

  const column_opts = {
    title: "Latest Assets",
    target: "/library",
    cls: "LatestItem",
    items: assets,
    home_url: home_url,
  };

  return (
    <>
      <ModalWrap visible={modal.visible} content={modal.content} />
      <Column {...column_opts}>
        <LibraryTags home_url={home_url} />
      </Column>
    </>
  );
}

// conf
import { conf } from "conf/plone";
// react library
import { useFetch } from "usehooks-ts";
// app components
import Spinner from "components/spinner";
import Column from "./column";
import { time_since } from "helpers/helpers";
import ErrorMessage from "components/error_message";
// template
import TemplateComponent from "react-mustache-template-component";
// templates
import TMPL from "templates";


function remap(item) {
  const tmp = Object.assign({}, item);
  tmp.added_at_ago = time_since(new Date(tmp.added_at));
  tmp["@id"] = item["@id"].split("/api").pop().replace('/community-qa/', '/');
  return tmp;
}

export default function LatestQA({ home_url }) {
  const { data, error } = useFetch(
    conf.endpoints.latest_qa,
    conf.ajax_configuration_object,
  );

  if (error) return <ErrorMessage error={error} />;
  if (!data) return <Spinner />;

  const answers = [];
  data.forEach((item, idx) => {
    answers.push(
      <TemplateComponent
        key={idx}
        template={TMPL.qa_item}
        data={remap(item)}
        type="div"
        className="container"
        id={item.UID}
      />,
    );
  });

  const column_opts = {
    title: "Latest Q&A",
    target: "/qa",
    cls: "LatestItem",
    items: answers,
  };

  return <Column {...column_opts} />;
}

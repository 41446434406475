import { conf, library_portal_type_query } from "conf/plone";

const sort_labels_map = {
  "sortable_title:ascending": "Title (AZ)",
  "sortable_title:descending": "Title (ZA)",
  "upload_date:descending": "Newest",
  "upload_date:ascending": "Oldest",
  "relevance:ascending": "Relevance",
};

const from_obj_to_rel_url = (obj) => {
  if (obj.hasOwnProperty("@id")) {
    const id = obj["@id"];
    const tmp = id.split(`${conf.endpoints.root}/`);
    if (tmp.length > 1) return conf.route.base + tmp[1];
  }
  return null;
};

const from_url_to_id = (id) => {
  const tmp = id.split("/");
  return tmp[tmp.length - 1];
};

const clean_html_by_pattern = (raw, pattern) => {
  return raw.replaceAll(pattern, conf.endpoints.root);
};

const clean_raw_html = (raw) => {
  const _from = conf.app.link_replace_pattern.from;
  const _to = conf.app.link_replace_pattern.to;
  return raw.replaceAll(_from, _to);
};

const is_object = (obj) => {
  return obj != null && obj.constructor.name === "Object";
};

const is_string = (x) => {
  return Object.prototype.toString.call(x) === "[object String]";
};

const query_item_builder = (facet, value, type = "any") => {
  const querystring =
    type === "any"
      ? "plone.app.querystring.operation.selection.any"
      : "plone.app.querystring.operation.selection.all";
  const tmp = {
    i: facet,
    o: querystring,
    v: value,
  };
  return tmp;
};

const obj_to_qs = (obj) => {
  // transforms an object containing a state into a browser query string
  const _params = new URLSearchParams();
  // parsing facet
  if (obj.selected) {
    Object.keys(obj.selected).forEach((facet) => {
      obj.selected[facet].forEach((facet_value) => {
        //_params.append(facet.toLocaleLowerCase(), facet_value.toLocaleLowerCase());
        _params.append(facet, facet_value);
      });
    });
  }
  // parsing text
  if (obj.text && obj.text !== null && obj.text !== "") {
    _params.set("text", obj.text);
  }
  // parsing sort
  if (obj.sort_on && obj.sort_on !== null && obj.sort_on !== "") {
    _params.set("sort_on", obj.sort_on.value);
  }
  return _params.toString();
};

const qs_to_obj = (history, facets) => {
  // void obj;
  let obj = {
    text: "",
    selected: {},
    fire: 0,
    sort_on: null,
    page: 0,
    action: "facet_update",
    favorite: false,
  };
  let fire = 0;
  // try to interpret the querystring and reconstruct the state
  const _P = new URLSearchParams(history.search);
  if (_P.has("favorite")) {
    obj.favorite = true;
    return obj;
  }
  // try to load facets
  Object.keys(facets).forEach((facet) => {
    const data = _P.getAll(facet);
    if (data.length > 0) {
      obj.selected[facet] = data;
      fire = 1;
    }
  });

  // try to load text
  obj.text = _P.get("text") || "";
  if (obj.text !== "") fire = 1;

  // try to load sort
  // labels map
  const labels_map = sort_labels_map;
  obj.sort_on =
    _P.get("sort_on") !== null
      ? { value: _P.get("sort_on"), label: labels_map[_P.get("sort_on")] }
      : null;
  if (obj.sort_on === null) {
    obj.sort_on = { value: "upload_date:descending", label: "Newest" };
  }
  if (obj.sort_on !== null) fire = 1;

  obj.fire = fire;
  return obj;
};

const items_to_select2 = (items) => {
  let tmp = [];
  let selected = [];
  items.forEach((i) => {
    const obj = {
      value: i.value,
      label: i.title + ` (${i.total}) `,
      title: i.title,
    };
    if (i.selected && i.selected === true) {
      selected.push(obj);
    }
    tmp.push(obj);
  });
  // bonifing
  let _selected =
    selected.length === 0
      ? null
      : selected.length === 1
        ? selected[0]
        : selected;

  return {
    arr: tmp,
    selected: _selected,
  };
};

const getting_select_data_from_plone_result = (plone_facets_obj) => {
  let tmp = {};
  tmp.data = {};
  tmp.selected = {};
  const facet_list = Object.keys(plone_facets_obj);
  for (let i = 0; i < facet_list.length; i++) {
    const facet = facet_list[i];
    const res = items_to_select2(plone_facets_obj[facet]?.items);
    tmp.data[facet] = res.arr;
    tmp.selected[facet] = res.selected;
  }
  tmp.keys = facet_list;
  return tmp;
};

const from_state_to_query = (state, facets) => {
  let tmp = {
    query: [],
    facets: Object.keys(facets),
    //fullpath: `${conf.library.page}`,
    fullpath: "/",
    fullobjects: true,
    b_size: conf.library.results.page_size,
    b_start:
      (state.page || 0) === 0 ? 0 : state.page * conf.library.results.page_size,
  };

  // asset types in or
  let values = {};
  Object.keys(state.selected).forEach((facet) => {
    values[facet] = [];
  });

  Object.keys(state.selected).forEach((facet) => {
    state.selected[facet].forEach((value) => {
      // for all excet asset_type
      /*if (facet !== "asset_type") {
        tmp.query.push(query_item_builder(facet, value));
      } else {
        asset_type_values.push(value);
      }*/
      values[facet].push(value);
    });
  });
  Object.keys(state.selected).forEach((facet) => {
    if (values[facet].length > 0) {
      tmp.query.push(query_item_builder(facet, values[facet]));
    }
  });
  // flat tree
  /*if(asset_type_values.length > 0) {
    tmp.query.push(query_item_builder('asset_type', asset_type_values));
  }*/
  // check for searchable text
  if (state.text !== "") {
    tmp.query.push(query_item_builder("SearchableText", state.text));
  }
  // check for date
  if (state.sort_on !== null) {
    tmp.sort_on = state.sort_on.value.split(":")[0];
    tmp.sort_order = state.sort_on.value.split(":")[1];
  }
  if ("favorite" in state) {
    tmp.favorites = state.favorite === true ? true : false;
  }
  tmp.force_all = true;
  tmp.query.push(library_portal_type_query);
  return tmp;
};

const manage_favorite = async (url, action) => {
  const uri = url.endsWith("/") ? `${url}@${action}` : `${url}/@${action}`;
  let response;
  try {
    response = await fetch(uri, conf.ajax_configuration_object);
  } catch (error) {
    console.error(error);
    return false;
  }
  return response.status >= 200 && response.status <= 299;
};

const add_to_favorites = async (url) => {
  return manage_favorite(url, "add_favorite");
};

const remove_from_favorites = async (url) => {
  return manage_favorite(url, "remove_favorite");
};

function time_since(date) {
  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years";
  }
  interval = seconds / 2505600;
  if (interval > 1) {
    return Math.floor(interval) + " months";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes";
  }
  return Math.floor(seconds) + " seconds";
}

function local_date(date) {
  return date.toLocaleString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
}

let lastTrackedUrl = null; // Variabile per memorizzare l'ultimo URL tracciato

const track_download = (item, matomo) => {
  try {
    if (!matomo || typeof matomo._track_on_matomo !== "function") {
      console.error("Matomo instance or tracking function is not available");
      return -1;
    }

    const { asset_type, url, title } = item;
    const type = asset_type?.title;

    // Determine if the URL is absolute or relative
    const isAbsoluteUrl = /^(?:[a-z]+:)?\/\//i.test(url);
    const fullUrl = isAbsoluteUrl ? url : `${document.location.origin}${url}`;

    // Check if the URL is the same as the last tracked one
    if (fullUrl === lastTrackedUrl) {
      console.info("URL already tracked:", fullUrl);
      return; // Esce senza eseguire il tracking
    }

    // Update lastTrackedUrl with the current one
    lastTrackedUrl = fullUrl;

    console.info("tracking:", fullUrl);
    // Tracking on Matomo
    matomo._track_on_matomo(["setCustomDimension", 3, type]);
    matomo._track_on_matomo(["setDocumentTitle", title]);
    matomo._track_on_matomo(["setCustomUrl", fullUrl]);
    matomo._track_on_matomo(["trackPageView"]);
  } catch (error) {
    console.error("Error in send matomo data:", error.message);
    console.error(error.stack);
    return -1;
  }
};

const make_login_url = (uri) => {
  let url = conf.endpoints.default_came_from;
  const isValidUrl = (urlString) => {
    try {
      return Boolean(new URL(urlString));
    } catch (e) {
      return false;
    }
  };
  if (isValidUrl(uri)) {
    try {
      url = uri.split("#")[0].split('?')[0];
    } catch (e) {
      url = conf.endpoints.default_came_from;
    }
  }
  return conf.endpoints.login.replace("{from}", url);
};

export {
  getting_select_data_from_plone_result,
  is_string,
  is_object,
  from_obj_to_rel_url,
  from_url_to_id,
  query_item_builder,
  obj_to_qs,
  qs_to_obj,
  clean_html_by_pattern,
  clean_raw_html,
  from_state_to_query,
  add_to_favorites,
  remove_from_favorites,
  sort_labels_map,
  time_since,
  local_date,
  track_download,
  make_login_url,
};

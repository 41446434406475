// conf
import { conf } from "conf/plone";
// core
import React, { useState, useEffect } from "react";
// components
import FacetNavs from "./box_facet_navs";
import SearchBox from "./box_search";
import FacetResults from "./box_results";
import ResultNumbers from "./result_number";
import Spinner from "components/spinner";
import Select from "react-select";
import ModalWrap from "components/modalwrap";
import PageItem from "./item_page";
// helpers
import {
  obj_to_qs,
  from_state_to_query,
  sort_labels_map,
} from "helpers/helpers";

export default function FacetSearch({ clean_page, facets, url_state = null }) {
  const _facets = facets;
  const [results, set_results] = useState(null);
  // default date ordering only on library direct access
  const [state, set_state] = useState(
    clean_page
      ? {
          ...url_state,
        }
      : url_state,
  );
  // textbox outside state, becaouse it should be in state only after enter press
  const [search_text, set_search_text] = useState(url_state?.text || "");
  // sidebar facets, first time from component, next from queries
  const [side_facets, set_side_facets] = useState(_facets);
  // settings ajax request status
  const [is_loading, set_is_loading] = useState(false);
  // for modal
  const [modal, set_modal] = useState({
    visible: false,
    content: "",
  });

  // sort options
  // value form plone field

  const sort_opts = [
    "sortable_title:ascending",
    "sortable_title:descending",
    "upload_date:descending",
    "upload_date:ascending",
    "relevance:ascending",
  ];

  const sort_options = sort_opts.map((x) => {
    return { value: x, label: sort_labels_map[x] };
  });

  // handle actions

  const handle_facet_selections = (facet, items) => {
    // clone state object
    let tmp = Object.assign({}, state);
    tmp.selected[facet] = items;
    if (tmp.selected[facet].length === 0) {
      delete tmp.selected[facet];
    }
    tmp.fire += 1;
    tmp.page = 0;
    tmp.action = "facet_update";
    set_state(tmp);
  };

  const handle_text_mutation = (text_string) => {
    set_state((cur_state) => ({
      ...cur_state,
      text: text_string,
      fire: cur_state.fire + 1,
      page: 0,
      action: "facet_update",
    }));
  };

  const handle_reset = () => {
    set_search_text("");
    set_state((cur_state) => ({
      ...cur_state,
      text: "",
      selected: {},
      fire: cur_state.fire + 1,
      page: 0,
      sort_on: null,
      action: "reset",
    }));
  };

  // observe state and fire query if needed
  useEffect(() => {
    async function post_data(url, obj) {
      const response = await fetch(url, obj);
      return response.json();
    }

    const tmp = from_state_to_query(state, _facets);
    let ajax_conf = Object.assign({}, conf.ajax_configuration_object);
    ajax_conf.method = "POST";
    ajax_conf.body = JSON.stringify(tmp);

    if (state.action === "facet_update" || state.action === "reset") {
      set_is_loading(true);
      post_data(
        `${conf.endpoints.root}${conf.library.endpoint}`,
        ajax_conf,
      ).then((data) => {
        set_results(data);
        set_side_facets(data.facets);
        set_is_loading(false);
        const _params = obj_to_qs(state);
        var _path_query = window.location.pathname + "?" + _params.toString();
        window.history.pushState(null, "", _path_query);
      });
    }

    if (state.action === "fetch_more") {
      post_data(
        `${conf.endpoints.root}${conf.library.endpoint}`,
        ajax_conf,
      ).then((data) => {
        set_results((cur_ress) => ({
          ...cur_ress,
          items: [...cur_ress.items, ...data.items],
        }));
        set_side_facets(data.facets);
      });
    }
  }, [state, _facets]);

  const is_obj = (obj) => {
    return obj === Object(obj);
  };

  const fetch_more = (evt) => {
    set_state((cur_state) => ({
      ...cur_state,
      action: "fetch_more",
      page: cur_state.page + 1,
    }));
  };

  const handle_click_on_result_item = (url) => {
    set_modal({
      visible: true,
      content: <PageItem url={url} />,
    });
  };

  const _results =
    results !== null &&
    is_obj(results) &&
    "items" in results &&
    Array.isArray(results.items)
      ? results.items
      : null;

  return (
    <div className="container container-side_column">
      <div className="container" id="library-side-nav">
        <div className="container">
          <h2 className="wk-1">Library</h2>
        </div>

        {!is_loading && (
          <>
            <div className="container flex y-center">
              <div
                className="field field-boolean"
                style={{ "marginRight": "auto" }}
              >
                <div className="checkbox">
                  <label>
                    <input
                      type="checkbox"
                      checked={state.favorite}
                      onChange={(evt) => {
                        set_state((prev) => ({
                          ...prev,
                          favorite: !prev.favorite,
                          action: "facet_update",
                          fire: prev.fire + 1,
                        }));
                      }}
                    />
                    <span>Show favorite assets</span>
                  </label>
                </div>
              </div>

              <ResultNumbers numbers={results?.items_total} />

              {(state.text.length > 0 || Object.keys(state.selected).length > 0) && (
                <a
                  title="Reset Filters"
                  href="/"
                  onClick={(evt) => {
                    evt.preventDefault();
                    handle_reset();
                  }}
                >
                  <i className="icon icon-close icon-small icon-wk-3-light"></i>{" "}
                </a>
              )}
            </div>
          </>
        )}

        <FacetNavs
          used={Object.keys(state.selected).length > 0}
          facets={side_facets}
          on_select={(facet, values) => handle_facet_selections(facet, values)}
        />

        <div className="side_column_toggle">
          <a href="#library-side-nav" className="opener">
            {" "}
            &raquo;{" "}
          </a>
          <a href="#void" className="closer">
            {" "}
            &laquo;{" "}
          </a>
        </div>
      </div>

      <ModalWrap visible={modal.visible} content={modal.content} />

      <div>
        <div
          className="container flex x-justify y-center"
          style={{ marginTop: "8px", flexWrap: "wrap" }}
        >
          <form className="inline" style={{ flex: "1 1 350px" }}>
            <SearchBox
              on_fire={(val) => handle_text_mutation(val)}
              value={search_text}
              on_change={(val) => set_search_text(val)}
            />
          </form>

          <div
            className="container flex x-justify y-center"
            style={{ marginBottom: 0 }}
          >
            <label>Sort by</label>
            <Select
              defaultValue={state.sort_on}
              classNamePrefix="react-select"
              onChange={(item) =>
                set_state((cur_state) => ({
                  ...cur_state,
                  fire: cur_state.fire + 1,
                  sort_on: item,
                }))
              }
              placeholder="Sort By..."
              className="react-select-container"
              options={sort_options}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  width: "10em",
                }),
              }}
            />
          </div>
        </div>

        {!is_loading && Array.isArray(_results) && results.items_total > 0 && (
          <FacetResults
            results={_results}
            response={results}
            uodate_result={(idx, value) => {
              let tmp = Object.assign({}, results);
              tmp.items[idx].favorite = value;
              set_results(tmp);
            }}
            fetch_more={fetch_more}
            handle_click={handle_click_on_result_item}
          />
        )}
        {!is_loading && results?.items_total === 0 && (
          <div>Sorry no results.</div>
        )}
        {is_loading && <Spinner />}
      </div>
    </div>
  );
}

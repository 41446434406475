// see https://react-jsonschema-form.readthedocs.io/en/v1.8.1/advanced-customization/
// conf
import { conf } from "conf/plone";
// core
import React, { useState, useMemo } from "react";
// hooks
import { useFetch } from "usehooks-ts";
import { useDocumentTitle } from "usehooks-ts";
// form schema render
import Form from "@rjsf/core";
import validator from "@rjsf/validator-ajv8";
// components
import HTMLDIV from "./htmldiv";
import Spinner from "./spinner";
import PostSubmitBox from "./post_submit_box";
import Redirector from "./redirector";
import { ClipLoader } from "react-spinners";
import ErrorMessage from "components/error_message";

async function post_data(url = "", data = {}) {
  let options = Object.assign({}, conf.ajax_configuration_object);
  options.method = "POST";
  options.body = JSON.stringify(data);
  const res = await fetch(conf.endpoints.root + url + "/@form", options);
  if (res.ok) {
    const response = await res.json();
    return response;
  } else {
    console.error("very bad server error");
    return null;
  }
}

function Errors({ errorlist }) {
  return <div>{JSON.stringify(errorlist)}</div>;
}

function create_schema_from_data(data) {
  if (data === undefined) return {};
  const _data = JSON.parse(JSON.stringify(data));
  const required_ids = _data.required;
  let tmp = {};
  for (const [key, value] of Object.entries(data.properties)) {
    if (value.factory === "Multiple Choice") {
      tmp[key] = {
        "ui:widget": "checkboxes",
      };
      // check if it is required becaouse checkboxes dont have this props
      if (required_ids.indexOf(key) > -1) {
        _data.properties[key].minItems = 1;
      }
    }
    if (value.factory === "Choice") {
      tmp[key] = {
        "ui:widget": "radio",
      };
    }
  }
  return {
    ui_schema: tmp,
    schema: _data,
  };
}

function ServerErrors({ errors_list }) {
  const label = errors_list.length > 1 ? "Errors" : "Error";
  return (
    <div className="panel panel-danger errors">
      <div className="panel-heading">
        <h3 className="panel-title">{label}</h3>
      </div>
      <ul className="list-group">
        {errors_list.map((err, idx) => {
          return (
            <li key={idx} className="list-group-item text-danger">
              {err.message}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

function transformErrors(errors, uiSchema) {
  return errors.map((error) => {
    if (error.name === "required") {
      error.message = "This field is required";
    }
    return error;
  });
}

function ErrorListTemplate(props) {
  const { errors } = props;
  return (
    <div className="container">
      <div className="panel panel-danger errors">
        <div className="panel-heading">
          <h3 className="panel-title">Form submission failed</h3>
        </div>
        <div>
          <p>Please correct the highlighted errors</p>
        </div>
      </div>
    </div>
  );
}

const adapt_from_server_error = (errors_list) => {
  const tmp = {};
  errors_list.forEach((elem) => {
    if ("field" in elem)
      tmp[elem.field] = { __errors: [elem?.message || "error"] };
  });
  return tmp;
};

export default function FormRender({
  title,
  xmlschema,
  path,
  uid,
  pagedata,
  postsubmit_data,
  collage = false,
}) {
  const on_submit = ({ formData }, e) => {
    set_is_sending(true);
    async function _post_data(path, formData) {
      const res = await post_data(path, formData);
      if (res === null) {
      }
      switch (res.success) {
        case true:
          set_error_state(false);
          set_submitted(true);
          set_form_result(res);
          set_is_sending(false);
          set_extra_errors({});
          break;
        case false:
          set_error_state(true);
          set_submitted(false);
          set_is_sending(false);
          set_extra_errors(adapt_from_server_error(res.errors));
          break;
        default:
          console.error(`errors from server: ${res.msg}`);
          set_error_state(true);
          set_submitted(false);
      }
    }
    _post_data(path, formData);
  };

  useDocumentTitle(`${title} | ${conf.site_title}`);
  const { data, error } = useFetch(
    `${conf.endpoints.root}${path}/@form`,
    conf.ajax_configuration_object,
  );
  const [is_sending, set_is_sending] = useState(false);
  const [submitted, set_submitted] = useState(false);
  const [error_state, set_error_state] = useState(null);
  const [form_data, set_form_data] = useState({});
  const [form_result, set_form_result] = useState({});
  const [extra_errors, set_extra_errors] = useState({});

  const { ui_schema, schema } = useMemo(
    () => create_schema_from_data(data),
    [data],
  );
  const on_error = (errors) => {
    console.table(errors);
    // jump to first element
    // hack.. unable to have id to element directly
    const prop = errors[0].property.startsWith(".")
      ? errors[0].property.replace(".", "")
      : errors[0].property;
    const tgt = "tagetik_" + prop;
    const scroll_opts = {
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    };

    document.getElementsByName(tgt)[0].scrollIntoView(scroll_opts);
  };

  const on_focus_error = (err) => {
    console.log("err " + err);
  };

  const form_name =
    "nameAttribute" in pagedata ? pagedata?.nameAttribute || "" : "";

  if (error) return <ErrorMessage error={error} />;
  if (!data) return <Spinner />;

  const std_classess =
    collage === true ? "container flex column" : "container flex column";

  if (!submitted)
    return (
      <div className={std_classess}>
        {pagedata && pagedata.formPrologue && (
          <HTMLDIV obj={pagedata?.formPrologue} bottom={true} />
        )}
        <div>
          <Form
            idPrefix={"tagetik"}
            uiSchema={ui_schema}
            name={form_name}
            schema={schema}
            validator={validator}
            onSubmit={on_submit}
            formData={form_data}
            onChange={(evt) => {
              set_form_data(evt.formData);
            }}
            onError={on_error}
            focusOnError={on_focus_error}
            noHtml5Validate={true}
            extraErrors={extra_errors}
            transformErrors={transformErrors}
            templates={{ ErrorListTemplate }}
          >
            <div>
              {is_sending && <button disabled>{pagedata.submitLabel}</button>}
              {!is_sending && (
                <button type="submit">{pagedata.submitLabel}</button>
              )}
              {pagedata.useCancelButton && (
                <button
                  onClick={(e) => {
                    set_form_data({});
                  }}
                  type="button"
                >
                  {pagedata.resetLabel}
                </button>
              )}
            </div>
          </Form>
          {is_sending && <ClipLoader color="#36d7b7" />}
        </div>
        {pagedata && pagedata.formEpilogue && (
          <HTMLDIV obj={pagedata?.formEpilogue} top={true} />
        )}
      </div>
    );

  const next_page = pagedata.thanksPageOverride ? pagedata.thanksPageOverride.replace("string:", "") : '';
  let overriding_skipping = false;
  // we have a page redirection?
  if (
    submitted &&
    (pagedata.thanksPageOverride !== null) & (next_page !== "")
  ) {
    if (pagedata.thanksPageOverrideAction.token === "redirect_to") {
      return <Redirector url={next_page} />;
    } else {
      return (
        <div>
          unmanaged action for {pagedata.thanksPageOverrideAction.token} value:{" "}
          {pagedata.thanksPageOverride}
        </div>
      );
    }
  } else {
    overriding_skipping = true;
  }

  if (
    (submitted && pagedata.thanksPageOverride === null) ||
    (submitted && overriding_skipping)
  ) {
    const thx_title =
      "thankstitle" in pagedata ? pagedata?.thankstitle || "Thanks" : "Thanks";
    // hacks...
    document.title = thx_title;
    return (
      <div className="container flex column">
        {pagedata && pagedata.thanksPrologue && (
          <HTMLDIV obj={pagedata?.thanksPrologue} bottom={true} />
        )}
        <div>
          <PostSubmitBox data={postsubmit_data} result={form_result} />
          {error_state === false && pagedata.thanksdescription}
          {error_state === true && <Errors errorlist={error_state} />}
        </div>
        {pagedata && pagedata.thanksEpilogue && (
          <HTMLDIV obj={pagedata?.thanksEpilogue} top={true} />
        )}
      </div>
    );
  }

  if (submitted) {
    return <div className="container flex column">Form Submitted.</div>;
  }
}

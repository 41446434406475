const TMPL = {};
TMPL.collage__default = `<div class="card-container">
    <div class="card card-collage">
        <a class="card-content" href="{{url}}">
            <div class="card-header">
                {{#title}}<h4>{{title}}</h4>{{/title}}
            </div>
            <div class="card-body">
                <div>{{description}}</div>

            </div>
            <div class="card-footer">
                <p>Read More <i class="icon icon-more icon-small"></i></p>
            </div>
        </a>
    </div>
</div>`;
TMPL.collage__highlighted = `<div class="card-container">
    <div class="card card-collage card-highlighted">
        <a class="card-content" href="{{url}}">
            <div class="card-header">
                {{#title}}<h4>{{title}}</h4>{{/title}}
            </div>
            <div class="card-body">
                <div>{{description}}</div>

            </div>
            <div class="card-footer">
                <p>Read More <i class="icon icon-more icon-small"></i></p>
            </div>
        </a>
    </div>
</div>`;
TMPL.easyform__default = `<div class="card-container">
    <div class="card card-form">
        <div class="card-content">
            <div class="card-header">
                {{#title}}<h4><a href="{{url}}">{{title}}</a></h4>{{/title}}
            </div>
            <div class="card-body">
                <div>{{description}}</div>
            </div>
            <div class="card-footer">
                <a href="{{url}}">Go to form</a>
            </div>
        </div>
    </div>
</div>`;
TMPL.easyform__highlighted = `<div class="card-container">
    <div class="card card-form card-highlighted">
        <div class="card-content">
            <div class="card-header">
                {{#title}}<h4><a href="{{url}}">{{title}}</a></h4>{{/title}}
            </div>
            <div class="card-body">
                <div>{{description}}</div>
            </div>
            <div class="card-footer">
                <a href="{{url}}">Go to form</a>
            </div>
        </div>
    </div>
</div>`;
TMPL.folder__default = `<div class="card-container">
    <div class="card card-folder">
        <a class="card-content" href="{{url}}">
            <div class="card-header">
                {{#title}}<h4>{{title}}</h4>{{/title}}
            </div>
            <div class="card-body">
                <div>{{description}}</div>

            </div>
            <div class="card-footer">
                <p>Read More <i class="icon icon-more icon-small"></i></p>
            </div>
        </a>
    </div>
</div>`;
TMPL.folder__highlighted = `<div class="card-container">
    <div class="card card-folder card-highlighted">
        <a class="card-content" href="{{url}}">
            <div class="card-header">
                {{#title}}<h4>{{title}}</h4>{{/title}}
            </div>
            <div class="card-body">
                <div>{{description}}</div>

            </div>
            <div class="card-footer">
                <p>Read More <i class="icon icon-more icon-small"></i></p>
            </div>
        </a>
    </div>
</div>`;
TMPL.fragment__default = `<div>

{{#title}}
    <h4>
        {{#url}}
        <a href="{{url}}" target="{{url_target}}">
        {{/url}}
            {{title}}
        {{#url}}
        </a>
        {{/url}}
    </h4>
{{/title}}


{{#text}}
    <div>{{{text}}}</div>
{{/text}}
</div>`;
TMPL.fragment__imagebox = `<a class="imagebox" href="{{url}}" target="{{url_target}}">
    <img src={{filename}} />
    {{#title}}
    <h4>{{title}}</h4>
    {{/title}}
    {{#text}}
    <div>{{{text}}}</div>
    {{/text}}
</a>
`;
TMPL.image__default = `<div>
{{#image}}
{{#scales}}
{{#large}}
<img src="{{download}}" />
{{/large}}
{{/scales}}
{{/image}}
</div>`;
TMPL.image__highlighted = `<div>
{{#image}}
{{#scales}}
{{#large}}
<img src="{{download}}" />
{{/large}}
{{/scales}}
{{/image}}
</div>`;
TMPL.library_item__default = `<div class="card-container">
    <div class="card card-library_item card-library_item--{{asset_type_color}}">

        <a {{#quick_download}}
           class="card-preview js-file-download" href="{{file.download}}"
           {{/quick_download}}
           {{^quick_download}}
           class="card-preview react-managed" data-url="{{url}}"" data-type="{{link_type}}" href="{{url}}"
           {{/quick_download}}
        >

            {{#asset_type}}<i class="brand-icon brand-icon-medium brand-icon-{{token}}"></i>{{/asset_type}}

            <div>{{#file}}
                <small>{{size_formatted}} {{#size_formatted}}|{{/size_formatted}} {{content-type_formatted}}</small>
            {{/file}}</div>

            <div><i class="icon icon-{{action_icon}} icon-small icon-neutral-3-dark"></i> {{action_label}}</div>
        </a>

        <a class="card-content react-managed" data-url="{{remote_url}}"" data-type="{{link_type}}" href="{{url}}">
            <div class="card-header">
                    <div>
                        <h6>{{#asset_type}}{{title}} |{{/asset_type}}  <time datetime="{{upload_date}}">{{upload_date_formatted}}</time></h6>
                        {{#favorite}}
                            <div data-tooltip="Remove from favorites" data-tooltip-position="left"><i class="icon js-favorite icon-favorite-active icon-small" ></i></div>
                        {{/favorite}}
                        {{^favorite}}
                            <div data-tooltip="Add to favorites" data-tooltip-position="left"><i class="icon js-favorite icon-favorite icon-small" ></i></div>
                        {{/favorite}}
                    </div>
                <h4>{{#title}}{{title}}{{/title}}</h4>
            </div>
            <div class="card-body">
                <div>
                    {{description}}
                </div>
                <div  class="card-body-more">
                  <p>Read More</p>
                </div>
            </div>

        </a>

    </div>
</div>`;
TMPL.library_item__highlighted = `<div class="card-container">
    <div class="card card-highlighted card-library_item card-library_item--{{asset_type_color}}">

        <a {{#quick_download}}
           class="card-preview js-file-download" href="{{file.download}}"
           {{/quick_download}}
           {{^quick_download}}
           class="card-preview react-managed" data-url="{{url}}"" data-type="{{link_type}}" href="{{url}}"
           {{/quick_download}}
        >

            {{#asset_type}}<i class="brand-icon brand-icon-medium brand-icon-{{token}}"></i>{{/asset_type}}

            <div>{{#file}}
                <small>{{size_formatted}} | {{content-type_formatted}}</small>
            {{/file}}</div>

            <div><i class="icon icon-{{action_icon}} icon-small icon-neutral-3-dark"></i> {{action_label}}</div>
        </a>

        <a class="card-content react-managed" data-url="{{remote_url}}"" data-type="{{link_type}}" href="{{url}}">
            <div class="card-header">
                    <div>
                        <h6>{{#asset_type}}{{title}} |{{/asset_type}}  <time datetime="{{upload_date}}">{{upload_date_formatted}}</time></h6>
                        {{#favorite}}
                            <div data-tooltip="Remove from favorites" data-tooltip-position="left"><i id="favorite" class="icon icon-favorite-active icon-small" ></i></div>
                        {{/favorite}}
                        {{^favorite}}
                            <div data-tooltip="Add to favorites" data-tooltip-position="left"><i id="favorite" class="icon icon-favorite icon-small" ></i></div>
                        {{/favorite}}
                    </div>
                <h4>{{#title}}{{title}}{{/title}}</h4>
            </div>
            <div class="card-body">
                <div>
                    {{description}}
                </div>
                <div  class="card-body-more">
                  <p>Read More</p>
                </div>
            </div>

        </a>

    </div>
</div>`;
TMPL.qa_item = `<div class="card-container">
    <div class="card card-qa_item">
        <div class="card-preview">
            <div>Score: {{vote_count}}</div>
            <div><strong>Answers: {{subs}}</strong></div>
            <div>Comments: {{comment_count}}</div>
            <div>Views: {{view_count}}</div>
        </div>
        <div class="card-content">

            <div class="card-header">
                <h4><a href="{{@id}}">{{title}}</a></h4>
            </div>
            <div class="card-body">
                <div>{{description}}</div>
            </div>
            <div class="card-footer">
                Asked by
                <i class="icon icon-user icon-small icon-neutral-2-dark"></i> <a href="/qa/user/{{user.id}}">{{author.fullname}}</a>
                <time datetime="{{added_at}}" title="{{added_at}}">{{added_at_ago}} ago</time> in
                {{#tags}}
                <span><i class="icon icon-tag icon-small icon-neutral-2-dark"></i><a href="/qa/questions?tag={{id}}">{{name}}</a></span>
                {{/tags}}
            </div>
        </div>
    </div>
</div>`;
export default TMPL;
import { from_obj_to_rel_url } from "helpers/helpers";

export default function Listing({ title, items }) {
  const rows = items.map((item, idx) => {
    return (
      <tr key={idx}>
        <td>{item.title}</td>
        <td>{item["@type"]}</td>
        <td>{item.description}</td>
        <td>
          <a href={from_obj_to_rel_url(item)}>{from_obj_to_rel_url(item)}</a>
        </td>
      </tr>
    );
  });

  return (
    <div className="col-md-8 page">
      <h3>{title}</h3>
      <div>
        <table className="bordered striped">
          <thead>
            <tr>
              <th>title</th>
              <th>type</th>
              <th>description</th>
              <th>url</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    </div>
  );
}

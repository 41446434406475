// style
import "scsss/app.scss";
// react core
import { Routes, Route } from "react-router-dom";
// custom components
import Main from "layouts/main";
import Library from "layouts/library";
import Spinner from "components/spinner";
import Redirector from "components/redirector";
import UIDLOADER from "components/uid_loader";
import HomePage from "layouts/home_page";
import Embed from "components/embedded/embed";
// conf
import { conf } from "conf/plone";
// hooks
import { useEffect, useState } from "react";

// store
import SiteContext from "store/site";

// matomo
import TagetikMatomo from "tagetik-matomo";
import { make_login_url } from "helpers/helpers";

// Component for handling external redirects
function ExternalRedirect({ url }) {
  useEffect(() => {
    window.location.href = url;
  }, [url]);
  return null;
}

// Custom hook to fetch data
const useFetchData = () => {
  const [data, setData] = useState({
    user_data: null,
    redirect_data: [],
    user_profile: null,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        // Fetch UserData, Redirects, and UserProfile concurrently
        const [userData, redirectData, userProfileData] = await Promise.all([
          fetchUserData(),
          fetchRedirects(),
          fetchUserProfile(),
        ]);

        setData({
          user_data: userData,
          redirect_data: redirectData.redirects || [],
          user_profile: userProfileData,
        });
      } catch (error) {
        setError(error);
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { ...data, loading, error };
};

// Fetch UserData with POST using async/await
const fetchUserData = async () => {
  try {
    const post_options = { ...conf.ajax_configuration_object, method: "POST" };
    const res = await fetch(conf.endpoints.userdata, post_options);
    if (!res.ok) {
      throw new Error(`User data fetch failed with status ${res.status}`);
    }
    const data = await res.json();
    return data;
  } catch (err) {
    console.error("User data fetch error:", err);
    return { status: "error", errorcode: err };
  }
};

// Fetch Redirects with GET using async/await
const fetchRedirects = async () => {
  try {
    const res = await fetch(conf.endpoints.redirects);
    if (!res.ok) {
      throw new Error(`Failed to load redirects with status ${res.status}`);
    }
    const data = await res.json();
    return data;
  } catch (err) {
    console.error("Redirect fetch error:", err);
    return { redirects: [] }; // Imposta un array vuoto in caso di errore
  }
};

// Fetch UserProfile with GET using async/await
const fetchUserProfile = async () => {
  try {
    const res = await fetch(conf.endpoints.userProfile);
    if (!res.ok) {
      throw new Error(`User profile fetch failed with status ${res.status}`);
    }
    const data = await res.json();
    return data;
  } catch (err) {
    console.error("User profile fetch error:", err);
    return { status: "error", errorcode: err };
  }
};

// Error and Loading components
const ErrorMessage = ({ message }) => <p>{message}</p>;
const LoadingSpinner = () => <Spinner />;

function App({request}) {
  const { user_data, redirect_data, user_profile, loading, error } =
    useFetchData();

  // Handle loading state
  if (loading) return <LoadingSpinner />;

  // Handle errors
  if (error)
    return <ErrorMessage message="There was an error loading the data." />;

  // Handle unknown user
  if (user_data?.status === "Unknown") {
    return <Redirector url={make_login_url(request || window.location.href)} />;
  }

  // Handle inactive user profile
  if (!user_profile?.is_active) {
    return <Redirector url={conf.endpoints.redirect_userprofile} />;
  }

  // Initialize Matomo
  const matomo_instance = new TagetikMatomo(
    conf.matomo.track_id,
    user_data.username,
    user_data.profile,
    user_data.role,
    true,
  );
  matomo_instance.init();
  matomo_instance.disable_log();
  window.mi = matomo_instance;

  return (
    <SiteContext.Provider
      value={{
        base: conf.baseroute,
        user: user_data,
        matomo: matomo_instance,
        redirects: redirect_data,
        userProfile: user_profile,
      }}
    >
      <div>
        <Routes>
          {redirect_data.map((redirect, index) => (
            <Route
              key={index}
              path={redirect.path}
              element={<ExternalRedirect url={redirect.url} />}
            />
          ))}
          {/* Predefined routes */}
          <Route path={conf.route.navigation} element={<Main />} />
          <Route path={conf.route.library} element={<Library />} />
          <Route path={conf.route.library_item} element={<Main />} />
          <Route
            path={conf.route.library_item_from_uid}
            element={<UIDLOADER />}
          />
          <Route path={conf.route.embedded} element={<Embed />} />
          <Route path={conf.route.base} element={<HomePage />} />
          <Route path={conf.route.home_page} element={<HomePage />} />
        </Routes>
      </div>
    </SiteContext.Provider>
  );
}

export default App;

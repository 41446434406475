// core
import React, { useEffect, useLayoutEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import PageItem from "components/facet/item_page";

export default function Embed(props) {

  const render = useRef(false);


  useLayoutEffect(() => {
    document.getElementById("header").remove();
    document.getElementById("footer").remove();
  }, []);

  useEffect(() =>{
    render.current = true;
  }, []);

  let location = useLocation();
  const _url = location.pathname.replace("/embed", "");

  if (render.current === null) return null;

  return (
    <div className="embeddedcontent">
      <PageItem url={_url} forceTarget={true}/>
    </div>
  );
}

// conf
import { conf } from "conf/plone";
// hooks
import { useState } from "react";
import { useFetch } from "usehooks-ts";
import { useDocumentTitle } from "usehooks-ts";
// components
import Spinner from "../spinner";
import Collage from "./collage";
import PageItem from "components/facet/item_page";
import ErrorMessage from "components/error_message";
// modal
import ModalWrap from "components/modalwrap";

export default function CollagePage({ title, data, path }) {
  const [modal, set_modal] = useState({
    visible: false,
    content: "",
  });

  const onTrigger = (evt, id, data) => {
    evt.preventDefault();
    set_modal({
      visible: true,
      content: <PageItem url={data.url} />,
    });
  };

  useDocumentTitle(`${title} | ${conf.site_title}`);
  const response = useFetch(
    conf.endpoints.root + path + "/@get-tree",
    conf.ajax_configuration_object,
  );

  if (response.error) return <ErrorMessage error={response.error} />;
  if (!response.data) return <Spinner />;

  if ("tree" in response.data)
    return (
      <>
        <ModalWrap visible={modal.visible} content={modal.content} />
        <div id="collage-wrapper">
          {response.data.tree.map((item) => {
            return (
              <Collage
                key={item.UID}
                id={item.UID}
                rows={item.rows}
                background={item.background}
                onTrigger={onTrigger}
              />
            );
          })}
        </div>
      </>
    );

  return <p>There is an error, see logs.</p>;
}

// core
import React from "react";

const unauthorized = `
<p>
  <strong style="font-weight:800;">Insufficient Privileges</strong>
</p>
<br/>
<p>
  You do not have sufficient privileges to view this page. If you believe you are receiving this message in error,
  please send an email to <a href="mailto:tgk-dl-community@wolterskluwer.com">tgk-dl-community@wolterskluwer.com</a>
</p>
`;

const notFound = `
<p>
  <strong style="font-weight:800;">Oops! Page not found</strong>
</p>
<br/>
<p>
  The requested URL is probably broken or the page has been removed.
</p>
`;

const defaultError = `
<p>
  <strong style="font-weight:800;">There is an error</strong>
</p>
<br/>
<p>
  Please try again later.
</p>
`;

export default function ErrorMessage({ error }) {
  let msg = "";
  switch (error.message.toLowerCase()) {
    case "unauthorized":
      msg = unauthorized;
      break;
    case "not found":
      msg = notFound;
      break;
    default:
      msg = defaultError;
  }

  const errorWrapperStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "75vh",
    textAlign: "center",
    padding: "20px",
    backgroundColor: "white",
    fontSize: "larger",
  };

  const errorMessageStyle = {
    display: "block",
    maxWidth: "600px",
    margin: "0 auto",
    textAlign: "left",
  };

  return (
    <div className="error_wrapper" style={errorWrapperStyle}>
      <div
        style={errorMessageStyle}
        dangerouslySetInnerHTML={{ __html: msg }}
      />
    </div>
  );
}

import { useState } from "react";
import { add_to_favorites, remove_from_favorites } from "helpers/helpers";
// template
import TemplateComponent from "react-mustache-template-component";
// templates
import TMPL from "templates";

export default function LibraryItemSmall({ item, onClick, highlighted }) {
  const [data, setData] = useState(Object.assign({ favorite: "" }, item));
  const handle_click = (evt) => {
    // mange click on href
    if (evt.target.id === "favorite") {
      evt.stopPropagation();
      evt.preventDefault();

      const url = data['@id'];
      if(data.favorite === true) {
        remove_from_favorites(url).then((res) =>{
          if(res === false) return;
          setData((prev) => ({
            ...prev,
            favorite: false
          }));
        })
      }
      else {
        add_to_favorites(url).then((res) =>{
          if(res === false) return;
          setData((prev) => ({
            ...prev,
            favorite: true
          }));
          console.log("★");
        })
      }
    } else onClick(evt);
  };

  const template = highlighted ? TMPL.library_item__highlighted : TMPL.library_item__default;

  return (
      <TemplateComponent
        key={data.UID}
        template={template}
        data={data}
        onClick={handle_click}
      />
  );
}

const SubMittedFields = ({ data, mapping }) => {
  const fields = [];

  for (const [key, value] of Object.entries(data)) {
    fields.push(
      <div key={key}>
        <dt><strong>{mapping[key]}</strong>:</dt>
        <dd>{value}</dd>
        <hr />
      </div>
    );
  }
  return <div>{fields}</div>;
};

const PostSubmitBox = ({ data, result }) => {
  let _form_data = result.data;
  const _fields = data.showFields;
  const _mapping = result.mapping;

  if (data.showAll === false) {
    const tmp = {};
    const keys = Object.keys(_form_data);
    for (let i = 0; i < keys.length; i++) {
      if (_fields.indexOf(keys[i]) > -1) {
        tmp[keys[i]] = _form_data[keys[i]];
      }
    }
    _form_data = tmp;
  }

  return (
    <div>
      <h3>Your Submitted Data:</h3>
      <dl>
      <SubMittedFields data={_form_data} mapping={_mapping} />
      </dl>
    </div>
  );
};

export default PostSubmitBox;

// conf
import { conf } from "conf/plone";
// react library
import { useFetch } from "usehooks-ts";
// app components
import Spinner from "components/spinner";
import Slider from "react-slick";
import ErrorMessage from "components/error_message";
// imgs
import newsbg_general from "imgs/newsbg_general.jpg";
import newsbg_product from "imgs/newsbg_product.jpg";
import newsbg_sales from "imgs/newsbg_sales.jpg";
function CarouselItem({ item }) {
  const { title, date, subtitle, more, url, cover, news_type } = item;

  const bg_map = {
    sales: newsbg_sales,
    product: newsbg_product,
    general: newsbg_general,
  };
  const bg_image = cover === null ? bg_map[news_type] : cover;

  return (
    <div className="carousel-item"
      style={{ backgroundImage: `url(${bg_image})` }}>

      <div className="container carousel-item-content">
        <h2>{title}</h2>

        <div>
          <div><small>{date}</small></div>
          <p>{subtitle}</p>
        </div>

        <div>
          <a className="button button-medium button-wk2" href={url}>{more}</a>
        </div>
      </div>


    </div>
  );
}

export default function HomeCarousel({ url }) {
  const { data, error } = useFetch(url, conf.ajax_configuration_object);
  if (error) return <ErrorMessage error={error} />;
  if (!data) return <Spinner />;

  const opts = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  const slides = [];
  data.forEach((item, idx) => {
    slides.push(<CarouselItem key={idx} item={item} />);
  });

  return <Slider {...opts}>{slides}</Slider>;
}

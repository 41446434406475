import CollageFragment from "./fragment";

export default function CollageRow({ id, data, onTrigger }) {
  return (
    <div className="container container-3_columns" id={id}>
      {data.map((obj, idx) => {
        return (
          <CollageFragment key={idx} data={obj} onTrigger={onTrigger} />
        );
      })}
    </div>
  );
}

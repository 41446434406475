import { clean_raw_html } from "helpers/helpers";

export default function BreadCumbs({ breadcumbs = { items: [], root: "" } }) {
  const Links = breadcumbs.items.map((item, idx) => {
    return (
      <li key={idx}>
        <a href={clean_raw_html(item["@id"])}>{item.title}</a>
      </li>
    );
  });

  if (breadcumbs.items.length === 0 && breadcumbs.root === "") return <></>;
  else
    return (
      <div className="container">
        <nav className="breadcrumbs">
          <ol className="col-md-12">
            <li>
              <a href={clean_raw_html(breadcumbs.root)}>Home</a>
            </li>
            {Links}
          </ol>
        </nav>
      </div>
    );
}

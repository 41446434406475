// conf
import { conf } from "conf/plone";
// core
import React from "react";
// hooks
import { useState, useEffect } from "react";
import { useFetch } from "usehooks-ts";
// components
import Spinner from "components/spinner";
import DownloadForm from "components/facet/download_block_form";
import ErrorMessage from "components/error_message";
// helpers
import { is_object, is_string, clean_raw_html } from "helpers/helpers";
import { add_to_favorites, remove_from_favorites } from "helpers/helpers";
import { track_download } from "helpers/helpers";

import TMPL from "templates";
import TemplateComponent from "react-mustache-template-component";

// context
import { useContext } from "react";
// store
import SiteContext from "store/site";

// we need event?
const track = (evt, item, matomo) => {
  track_download(item, matomo);
};

function VideoDownloader({ url }) {
  const { matomo } = useContext(SiteContext);
  const { data, error } = useFetch(url);
  if (error) return <ErrorMessage error={error} />;
  if (!data) return <Spinner />;
  if (
    data &&
    data.video_url &&
    Array.isArray(data.video_url) &&
    data.video_url.length > 0
  ) {
    return (
      <div>
        <a
          onClick={(evt) => track(evt, data, matomo)}
          className="button button-wk1"
          href={data.video_url[0]}
          rel="noreferrer"
          target="_blank"
        >
          <i className="icon icon-download icon-small icon-neutral-1-light"></i>
          Download Video
        </a>
      </div>
    );
  }
  return <></>;
}

function MutableContent({ type, data, onBtnPress }) {
  const { matomo } = useContext(SiteContext);
  switch (type) {
    case "video":
      return (
        <div className="container flex column y-center">
          <div>
            <iframe
              width={560}
              height={315}
              title={data.title}
              frameborder="0"
              allowFullScreen="true"
              className="video-player"
              src={`${data.remote_url}/embed_player`}
            ></iframe>
          </div>
          {data.show_video_download === true && (
            <VideoDownloader url={data.json_videodata_url} />
          )}
        </div>
      );
    case "download":
      return (
        <div className="container">
          <a
            href={data.file?.download}
            className="button button-wk1"
            onClick={(evt) => track(evt, data, matomo)}
          >
            <i className="icon icon-download icon-small icon-neutral-1-light"></i>
            {data.action_label}{" "}
            <small>
              {data.file.size_formatted} |
              <span> {data.file["content-type_formatted"].toUpperCase()}</span>
            </small>
          </a>
        </div>
      );
    case "download-blocked":
      return <DownloadForm obj={data} onBtnPress={onBtnPress} />;
    case "link":
      return (
        <div className="container">
          <a href={data?.remote_url} className="button button-wk1">
            <i
              className={
                "icon icon-small icon-neutral-1-light icon-" + data.action_icon
              }
            ></i>
            {data.action_label}{" "}
            <small>
              {data.file.size_formatted} |
              <span> {data.file["content-type_formatted"].toUpperCase()}</span>
            </small>
          </a>
        </div>
      );
    default:
      return <></>;
  }
}

function Permalink({ uid, target = null }) {
  const html_target = target === null ? "_self" : "_blank";
  return (
    <p>
      <small>
        Permalink: &nbsp;
        <a
          className="perma"
          href={`${conf.portal}/goto/${uid}`}
          target={html_target}
        >
          {`${conf.portal}/goto/${uid}`}
        </a>
      </small>
    </p>
  );
}

function PublishingRestrictions({ roles }) {
  if (roles.length > 0) {
    return (
      <div className="container flex">
        <div>
          <small style={{ "white-space": "nowrap" }}>
            <strong>Publishing restrictions.</strong> This item is published
            for:
          </small>
        </div>
        <div>
          <small>
            <ul className="inline">
              {roles.map((item, index) => (
                <li>
                  <span className="pill" key={index}>
                    {item.title}
                  </span>
                </li>
              ))}
            </ul>
          </small>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}

export default function PageItem({ url, forceTarget = false }) {
  const rel_path = url.startsWith("/") ? url : new URL(url).pathname;
  const [hide_body, set_hide_body] = useState(false);
  const [favorite, setFavorite] = useState(false);
  const [relatedItems, setRelatedItems] = useState([]);
  const { data, error } = useFetch(
    conf.endpoints.root + rel_path,
    conf.ajax_configuration_object,
  );

  useEffect(() => {
    console.log(data);
    if (data && data?.favorite) {
      setFavorite(data.favorite);
    }
    if(data && data.relatedItems?.length > 0) {
      setRelatedItems(data.relatedItems);
    }
  }, [data]);

  if (error) return <ErrorMessage error={error} />;
  if (!data) return <Spinner />;

  let tmp = {};
  for (let i = 0; i <= conf.facets.length; i++) {
    const facet_name = conf.facets[i];
    if (Array.isArray(data[facet_name]) && data[facet_name].length > 0) {
      tmp[facet_name] = data[facet_name];
    } else if (facet_name == "external_usage" && data[facet_name]) {
      tmp[facet_name] = [
        {
          title: "External usage: " + data[facet_name]["title"],
          token: data[facet_name]["token"],
        },
      ];
    }
  }

  // check if text is string or html
  let html_data;
  let is_html = false;

  if (!is_string(data.text)) {
    try {
      is_html =
        is_object(data.text) &&
        data.text.data &&
        data.text["content-type"] === "text/html";
      html_data = clean_raw_html(data.text.data);
    } catch (err) {
      is_html = false;
      html_data = "";
    }
  }

  let metaDatas = Object.keys(tmp).map(function (item, idx) {
    const _target = forceTarget === false ? "_self" : "_blank";
    return (
      <li key={idx}>
        {tmp[item].map((i, idx) => (
          <a
            key={idx}
            href={`/library?${item}=${i.token}`}
            className="pill"
            target={_target}
          >
            {i.title}
          </a>
        ))}
      </li>
    );
  });

  const handle_favorite = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();

    const url = data["@id"];
    if (favorite === true) {
      remove_from_favorites(url).then((res) => {
        if (res === false) return;
        setFavorite(false);
      });
    } else {
      add_to_favorites(url).then((res) => {
        if (res === false) return;
        setFavorite(true);
        console.log("★");
      });
    }
  };

  const updateRelated = (obj, is_favorite) => {
    setRelatedItems((prevRelatedItems) =>
      prevRelatedItems.map((item) =>
        item.UID === obj.UID ? { ...item, favorite: is_favorite } : item
      )
    );
  };

  const handle_click = (evt, idx, item) => {
    // mange click on href
    // check for direct clicking on icon favorite
    if (evt.target.classList.contains("js-favorite")) {
      evt.stopPropagation();
      evt.preventDefault();
      const url = item["@id"];
      if (item.favorite === true) {
        remove_from_favorites(url).then((res) => {
          if (res === false) return;
          console.log("-");
          updateRelated(item, false);
        });
      } else {
        add_to_favorites(url).then((res) => {
          if (res === false) return;
          console.log("★");
          updateRelated(item, true);
        });
      }
    }
  }

  return (
    <div>
      <div className="card-container">
        <div
          className={
            "card card-library_item card-library_item--" + data.asset_type_color
          }
        >
          <div className="card-content">
            <div className="card-header">
              <div>
                <h6>
                  {data.asset_type.title} |{" "}
                  <time dateTime={data.upload_date}>
                    {data.upload_date_formatted}
                  </time>
                </h6>
                {favorite === true && (
                  <div
                    onClick={handle_favorite}
                    data-tooltip="Remove from favorites"
                    data-tooltip-position="left"
                  >
                    <i className="icon icon-favorite-active icon-small"></i>
                  </div>
                )}
                {favorite !== true && (
                  <div
                    onClick={handle_favorite}
                    data-tooltip="Add to favorites"
                    data-tooltip-position="left"
                  >
                    <i className="icon icon-favorite icon-small"></i>
                  </div>
                )}
              </div>
              <h4>{data.title}</h4>
            </div>

            <div className="card-body">
              <div>
                {/* Hack for style; this will not crop text */}
              </div>

              {hide_body === false && (
                <>
                  {is_html && (
                    <div
                      className="container"
                      dangerouslySetInnerHTML={{ __html: html_data }}
                    />
                  )}
                  {!is_html && (
                    <div className="container">{data.description}</div>
                  )}
                </>
              )}

              <MutableContent
                type={data.link_type}
                data={data}
                onBtnPress={(evt) => {
                  set_hide_body(true);
                }}
              />

              <div className="container">
                <ul className="inline">{metaDatas}</ul>
              </div>

              <div className="container">
                {relatedItems.length > 0 && (<h5>Related items</h5>)}
                {relatedItems.map((item, index) => {
                  // hack to force redraw
                  const idx = index.toString() + "_" + item.favorite.toString();
                  return (
                    <TemplateComponent
                      key={idx}
                      template={TMPL.library_item__default}
                      data={item}
                      type="div"
                      className="container"
                      id={item.UID}
                      onClick={(evt) => handle_click(evt, index, item)}
                    />
                  );
                })}
              </div>

            </div>

            <div className="card-footer">
              <Permalink uid={data.UID} target={forceTarget} />
              <PublishingRestrictions roles={data.published_for} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

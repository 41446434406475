import React, { useEffect } from 'react';
import { useLocation, useNavigationType } from 'react-router-dom';

const RouteChangeHandler = () => {
  const location = useLocation();
  const navigationType = useNavigationType();

  useEffect(() => {
    const removeScript = () => {
      const script = document.querySelector('script[src="https://eu-admin.eventscloud.com/widgets/static/embed/embed-0.0.1.min.js"]');
      if (script) {
        script.remove();
        console.log('Script rimosso');
      }
    };

    if (navigationType === 'PUSH' || navigationType === 'REPLACE') {
      removeScript();
    }
  }, [location, navigationType]);

  useEffect(() => {
    const addScript = () => {
      const aventriWidget = document.querySelector('.aventri-widget');
      if (aventriWidget) {
        const script = document.createElement('script');
        script.src = "https://eu-admin.eventscloud.com/widgets/static/embed/embed-0.0.1.min.js";
        script.async = true;
        document.body.appendChild(script);
        console.log('Script aggiunto');
      }
    };

    const observer = new MutationObserver((mutationsList, observer) => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'childList') {
          if (document.querySelector('.aventri-widget')) {
            addScript();
            observer.disconnect();
            break;
          }
        }
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
    };
  }, [location]);

  return null;
};

export default RouteChangeHandler;

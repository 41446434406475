import CollageRow from "./row";

export default function Collage({ id, rows, background, onTrigger }) {
  return (
    <section className={background}>
      {rows.map((row) => {
        return (
          <CollageRow
            key={row.UID}
            id={row.UID}
            data={row.fragments}
            onTrigger={onTrigger}
          />
        );
      })}
    </section>
  );
}

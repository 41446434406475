// conf
import { conf } from "conf/plone";
// core
import React from "react";
import { useParams } from "react-router-dom";

// hooks
import { useFetch } from "usehooks-ts";
// components
import Spinner from "./spinner";
import { clean_raw_html } from "helpers/helpers";
import { useNavigate } from "react-router-dom";
import ErrorMessage from "components/error_message";

export default function UIDLOADER(props) {
  const { uid } = useParams();
  const url = `${conf.endpoints.root}/@search?UID=${uid}`;
  const { data, error } = useFetch(url, conf.ajax_configuration_object);
  const navigate = useNavigate();

  if (error) return <ErrorMessage error={error} />;
  if (!data) return <Spinner />;
  let item = null;
  if (data.items_total > 0) {
    item = data.items[0]["@id"];
    item = clean_raw_html(item);
  }
  if (item !== null) navigate(item);

  return <div className="container">Page not found.</div>;
}

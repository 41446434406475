// core
import React from "react";
// helpers
import { from_obj_to_rel_url, from_url_to_id } from "helpers/helpers";

const LinksItems = (data, active_id = "") => {
  const Links = data.map((item, idx) => {
    const classess = active_id === from_url_to_id(item["@id"]) ? "active" : "";
    return (
      <li className={classess} key={idx}>
        <a href={from_obj_to_rel_url(item)}>{item.title}</a>
      </li>
    );
  });

  return Links;
};

const TreeLinks = (data) => {
  const Links = data.map((item, idx) => {
    const classess = item.is_current === true ? "current" : "";
    return (
      <li key={idx}>
        <a className={classess} href={from_obj_to_rel_url(item)}>
          {item.title}
        </a>
        {item.items.length > 0 && <ol>{TreeLinks(item.items)}</ol>}
      </li>
    );
  });

  return Links;
};

const LinksBar = ({ className = "", data, active_id = "", expand = false }) => {
  if (expand === false) {
    const Links = LinksItems(data, active_id);
    return <ol className={className}>{Links}</ol>;
  } else {
    const Links = TreeLinks(data);
    return <ol className={className}>{Links}</ol>;
  }
};

export default LinksBar;

export default function Column({ title, target, cls, items, children }) {
  return (
      <div className={cls} style={{'width': '100%'}}>
        <h2>
          <a href={target}>{title}</a>
        </h2>
        <br />
        <div>{items}</div>
        {children}
      </div>
  );
}

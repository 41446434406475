// conf
import { conf } from "conf/plone";
// core
import React from "react";
// hooks
import { useFetch } from "usehooks-ts";
// components
import LinksBar from "./linksbar";
import Spinner from "./spinner";
import { from_obj_to_rel_url } from "helpers/helpers";

const params =
  "/@contextnavigation?expand.contextnavigation.topLevel=1&expand.contextnavigation.includeTop=true";

export default function PathBar({ url }) {
  const { data, error } = useFetch(
    conf.endpoints.root + url + params,
    conf.ajax_configuration_object,
  );

  if (error) return <p>There is an error.</p>;
  if (!data) return <Spinner />;

  const _items = data.items.filter((i, index) => {
    return (
      index !== 0 &&
      i.type === "folder" &&
      !conf.excluded_from_nav.includes(i.normalized_id)
    );
  });

  return (
    <div id="navbar-col">
      {data.items.length > 0 && (
        <div className="container">
          <h3 className="wk-2">
            <a href={from_obj_to_rel_url(data.items[0])}>
              {data.items[0].title}
            </a>
          </h3>
        </div>
      )}

      <nav>
        <LinksBar data={_items} expand={true} />
      </nav>
      <div className="side_column_toggle">
        <a href="#navbar-col" className="opener">
          {" "}
          &raquo;{" "}
        </a>
        <a href="#void" className="closer">
          {" "}
          &laquo;{" "}
        </a>
      </div>
    </div>
  );
}

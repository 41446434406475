// conf
import { conf } from "conf/plone";
// react library
import { useLocation } from "react-router-dom";
import { useFetch } from "usehooks-ts";
// app components
import FacetSearch from "components/facet/facet_search";
import Spinner from "components/spinner";
import ErrorMessage from "components/error_message";
// hooks
import { useDocumentTitle } from "usehooks-ts";
// helpers
import { qs_to_obj } from "helpers/helpers";

export default function Library() {
  // page title
  useDocumentTitle(`${conf.library.title} | ${conf.site_title}`);
  const history = useLocation();
  const query = conf.facets.map((i) => `&facets=${i}`).join("");
  const { data, error } = useFetch(
    `${conf.endpoints.root}${conf.library.skeleton_query}${query}`,
    conf.ajax_configuration_object,
  );
  if (error) return <ErrorMessage error={error} />;
  if (!data) return <Spinner />;

  const url_state = qs_to_obj(history, data.facets);
  // if there arent params maybe we are visiting library page clean
  const clean_page = history.search === "";

  return (
    <>
      <main>
        <FacetSearch
          facets={data.facets}
          clean_page={clean_page}
          url_state={url_state}
        />
      </main>
    </>
  );
}

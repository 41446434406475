// react library
import { useContext } from "react";

// store
import SiteContext from "store/site";


function Tool({ cls, url, title, desc, icon }) {
  const classess = `imagebox ${cls}`;
  const _icon = `brand-icon brand-icon-${icon} brand-icon-medium img`;
  return (
    <div>
        <a href={url} className={classess}>
          <i className={_icon}></i>
        <h4>{title}</h4>
        <div>{desc}</div>
        </a>
        </div>
  );
}


// TODO: Check visibility on role / profile
const block_data = [
  {
    cls: "",
    url: "/library",
    title: "Library",
    desc: "Browse our resources to enhance your understanding of Tagetik",
    icon: "library",
    roles: ['CLI', 'CSP', 'STP', 'EMP', 'RES', 'DIS'],
  },
  {
    cls: "",
    url: "/qa",
    title: "Q&A",
    desc: "Ask your questions and interact with your peers",
    icon: "qa",
    roles: ['CLI', 'CSP', 'STP', 'EMP', 'RES', 'DIS']
  },
  {
    cls: "",
    url: "/employee-area",
    title: "Employee Area",
    desc: "The info you need to become a compliant Tagetik employee",
    icon: "employee-area",
    roles: ['EMP',]
  },
  {
    cls: "",
    url: "https://insiders.tagetik.com/",
    title: "Insiders Hub",
    desc: "Access our exclusive advocacy platform for Customers and Partners",
    icon: "insiders-hub",
    roles: ['CSP', 'STP', ]
  },
  {
    cls: "",
    url: "/product",
    title: "Product",
    desc: "Find out all the latest information about our solutions",
    icon: "training-product",
    roles: ['RES', 'DIS', ]
  },
  {
    cls: "",
    url: "/library?favorite",
    title: "Favorite Assets",
    desc: "Access all the library assets you have selected as your favorites",
    icon: "favorite-asset",
    roles: ['CLI', 'CSP', 'STP', 'EMP', 'RES', 'DIS'],
  },
];

export default function Tools() {

  const { user } = useContext(SiteContext);
  const tools = [];

  block_data.forEach((item, idx) => {
    if (item.roles.indexOf(user.role) > -1) {
      tools.push(<Tool key={idx} {...item} />);
    }
  })

  return (
        <div className="container flex x-center same-width">
          {tools}
        </div>
  );
}

// conf
import { conf } from "conf/plone";
// hooks
import { useDocumentTitle } from "usehooks-ts";
// helpers
import { clean_raw_html, is_object, is_string } from "helpers/helpers";

export function SimpleComponent({
  title,
  raw_text,
  is_html = false,
  fullwidth = false,
  date = null,
}) {
  //const {site_url, user_info} = useContext(SiteContext);
  const html_data = clean_raw_html(raw_text);
  const classess = fullwidth ? "view" : "col-md-9 page";

  return (
    <div className={classess}>
      <h3>{title}</h3>
      {date !== null && <h3>{date}</h3>}
      {is_html && <div dangerouslySetInnerHTML={{ __html: html_data }} />}
      {!is_html && <div>{raw_text}</div>}
    </div>
  );
}

export default function Page({
  title,
  text,
  path,
  fullwidth = false,
  date = null,
}) {
  useDocumentTitle(`${title} | ${conf.site_title}`);
  // check if page as html block
  if (is_object(text) && text.data) {
    return (
      <SimpleComponent
        title={title}
        raw_text={text.data}
        is_html={true}
        date={date}
        fullwidth={fullwidth}
      />
    );
  } else if (is_string(text)) {
    return (
      <SimpleComponent
        title={title}
        raw_text={text}
        is_html={false}
        date={date}
        fullwidth={fullwidth}
      />
    );
  } else {
    const msg = "sorry, i'm unable to render this page";
    return (
      <SimpleComponent
        title={title}
        raw_text={msg}
        is_html={false}
        date={date}
        fullwidth={fullwidth}
      />
    );
  }
}

// this component tries to establish and render a block of html text
// hooks
import { useContext } from "react";
// helpers
import { clean_html_by_pattern, is_object, is_string } from "helpers/helpers";
// store
import SiteContext from "store/site";

export default function HTMLDIV({
  obj,
  className = "",
  top = null,
  bottom = null,
}) {
  const { site_url } = useContext(SiteContext);

  const TOP = () => {
    if (top !== null) return <hr />;
    return null;
  };

  const BOTTOM = () => {
    if (bottom !== null) return <hr />;
    return null;
  };

  if (is_object(obj) && obj.data && obj["content-type"]) {
    if (obj["content-type"] === "text/html") {
      return (
        <>
          <TOP />
          <div
            className={className}
            dangerouslySetInnerHTML={{
              __html: clean_html_by_pattern(obj.data, site_url),
            }}
          />
          <BOTTOM />
        </>
      );
    }
  } else if (is_string(obj)) {
    return <div className={className}>{`<p><${obj}/p>`}</div>;
  }

  return (
    <div className={className}>
      <strong>Error render method unsupported.</strong>
    </div>
  );
}

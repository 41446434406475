// core
import { getting_select_data_from_plone_result } from "helpers/helpers";
import FacetGroup from "./facet_grp";

export default function FacetNavs({ used, facets, on_select, extra_classess }) {
  const data = getting_select_data_from_plone_result(facets);
  const navs_new = data.keys.map((facet, id) => {
    return (
      <FacetGroup
        key={id}
        facet={facet}
        options={data.data[facet].filter((item) => item.value !== "")}
        selected={data.selected[facet]}
        on_select={on_select}
        is_facet_nav_used={used}
      />
    );
  });

  return (
    <div className={extra_classess}>
      <form>
        <fieldset>{navs_new}</fieldset>
      </form>
    </div>
  );
}

import { clean_raw_html, local_date } from "helpers/helpers";

function PressItemSummary({ item }) {
  const url = clean_raw_html(item["@id"]);
  const date = new Date(item.release_date);
  return (<>
    <div className="card-container">
        <div className="card card-plain-border">
            <a className="card-content" href={url}>
                <div className="card-header">
                    <strong>{local_date(date)}</strong>
                </div>
                <div className="card-body">
                  <h3>{item.title}</h3>
                  {item.description}
                  <div  class="card-body-more">
                    <p>Read More</p>
                  </div>
                </div>
            </a>
        </div>
    </div>
  </>);
}

export default function PressList({ data }) {
  const title = data.title;
  const items = data.items.filter((i) => i["@type"] === "Press Release");
  const rows = items.map((item, idx) => (
    <PressItemSummary key={idx} item={item} />
  ));

  return (
    <div>
      <div className="container"><h2>{title}</h2></div>
      <div className="container flex column">{rows}</div>
    </div>
  );
}

// conf
import { conf } from "conf/plone";
// react library
import { useFetch } from "usehooks-ts";
// app components
import Spinner from "components/spinner";
import ErrorMessage from "components/error_message";

function to_id(str) {
  const tmp = str.toLowerCase();
  const replaced = tmp.replace(/\W/g, " ");
  return replaced.replace(/\s+/g, "-");
}

function tag_link(text) {
  const tmp = encodeURIComponent(to_id(text));
  return `/library?asset_type=${tmp}`;
}

function TagsItem({ text }) {
  return (
      <li><a className="pill" href={tag_link(text)}><i className="icon icon-tag icon-small icon-neutral-2-dark"></i>{text}</a></li>
  );
}

export default function LibraryTags({ home_url }) {
  const url = `${home_url}/@library-tags`;
  const { data, error } = useFetch(url, conf.ajax_configuration_object);
  if (error) return <ErrorMessage error={error} />;
  if (!data) return <Spinner />;

  const tags = [];
  data.forEach((text, idx) => {
    tags.push(<TagsItem key={idx} text={text} />);
  });

  return (
    <div className="container selected-assets">
      <h4>Selected asset types for you</h4>
      <ul className="inline">{tags}</ul>
    </div>
  );
}

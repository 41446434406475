// conf
import { conf } from "conf/plone";
// react library
import { useContext } from "react";
import { useFetch } from "usehooks-ts";
// app components
import Spinner from "components/spinner";
// hooks
import { useDocumentTitle } from "usehooks-ts";
// store
import SiteContext from "store/site";
// comps
import { DynPageComponent } from "./main";
import HomeCarousel from "components/homepage/home_carousel";
import Tools from "components/homepage/tools";
import ShortCuts from "components/homepage/shortcuts";
import Latest from "components/homepage/latest";
import ErrorMessage from "components/error_message";


function cta_button(cta, color) {
  if (typeof cta == 'string' && cta.indexOf('|') > 0) {
    const cta_parts = cta.split('|');
    return <a href={cta_parts[1]} className={'button button-small button-' + color}>{cta_parts[0]}</a>
  } else {
    return <></>
  }
}

function ProfileContent({ text, image, cta1, cta2 }) {
  const backgroundImage = image && <img src={image.download} /> || <></>
  const cta1_button = cta_button(cta1, 'wk2')
  const cta2_button = cta_button(cta2, 'wk1')
  return (
    <div className="homepage-profile-content">
      {backgroundImage}
      <div className="container">
        <div dangerouslySetInnerHTML={{ __html: text }} />
        <div className="homepage-profile-content-actions">
          {cta1_button} {cta2_button}
        </div>
      </div>
    </div>
  );
}


export default function HomePage() {
  const { user } = useContext(SiteContext);

  // User with 'other' profile are redirected to the executive HP

  const target_page = (user.profile === 'other') ? 'executive' : user.profile;
  const url = `${conf.endpoints.root}/home-page/${target_page}`;  
  
  const carousel_url = `${url}/@carousel`;
  // page title
  useDocumentTitle(`Homepage | ${conf.site_title}`);
  const { data, error } = useFetch(url, conf.ajax_configuration_object);
  //const _pathname = "/"; // not sure...
  if (error) return <ErrorMessage error={error} />;
  if (!data) return <Spinner />;

  const related = "relatedItems" in data ? data.relatedItems : null;
  // const profile_content = ProfileContent()


  return (
    <>
      <main className="home-page">
        <div className="container carousel-parent">
          <HomeCarousel url={carousel_url} />
        </div>
        <div className="container tool-parent">
          <Tools />
        </div>
        <div className="container latest-parent">
          <Latest home_url={url} />
        </div>
        <div className="container shortcuts-parent">
          <ShortCuts data={related} />
        </div>
        <div className="container">
          <ProfileContent text={data.text.data} image={data.image} cta1={data.call_to_action_1} cta2={data.call_to_action_2} />

          {/* {JSON.stringify(data)}
          <DynPageComponent
            type={data["@type"]}
            layout={data.layout}
            data={data}
            path={_pathname}
          /> */}
        </div>
      </main>
    </>
  );
}

import LatestQA from "./latest_qa";
import LatestAssets from "./latest_assets";

export default function Latest({ home_url }) {
  return (
    <div className="container container-3_columns">
      <div>
      <LatestAssets home_url={home_url} />
      </div>
      <div>
      <LatestQA home_url={home_url} />
      </div>
    </div>
  );
}

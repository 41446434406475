// conf
import { conf } from "conf/plone";
// components
import Select from "react-select";

const get_value_from_label = (label_as_string) => {
  const regExp = /\(([^)]+)\)/;
  const matches = regExp.exec(label_as_string);
  let val = null;
  if (matches.length >= 2) {
    try {
      val = parseInt(matches[1].replace(/^\D+/g, ""));
    } catch (e) {
      console.log(e);
      val = null;
    }
  }
  return val;
};

function FacetTitle({ hash }) {
  const _string =
    Object.keys(conf.facets_readable).indexOf(hash) > -1
      ? conf.facets_readable[hash]
      : hash;
  return <label>{_string}</label>;
}
export default function FacetGroup({ facet, options, selected, on_select, is_facet_nav_used }) {
  const classess = selected !== null ? "facet_selected" : "";

  const sorted_options_by_title = [...options].sort((a, b) =>
    a.value.localeCompare(b.value),
  );

  const sorted_options_by_numbers = [...options].sort((a, b) => {
    const a_val = get_value_from_label(a.label);
    const b_val = get_value_from_label(b.label);
    return b_val - a_val || a.value.localeCompare(b.value);
  });

  const select_options = () => {
    const plus_icon = '<i class="icon icon-small icon-plus icon-neutral-2-dark"></i>'
    let sorted_options = options;

    if (facet !== 'versions' && facet !== 'regions' && facet !== 'industries') {
      sorted_options = sorted_options_by_title.map(({ value, label }) => ({
        label: label,
        value: value,
      }))
    }

    if (selected == null && is_facet_nav_used)  {
      sorted_options = sorted_options_by_title.map(({ value, label }) => ({
        label: label,
        value: value,
      }))

    } else if (selected !== null) {
      sorted_options = sorted_options_by_title.map(({ value, label }) => ({
        label: <span dangerouslySetInnerHTML={{ __html: `${label.replace(/\(\d+\)/g, '(+)')}` }} />,
        value: value,
      }))
    }







    return sorted_options

  }


  return (
    <div className="field">
      <FacetTitle classess={classess} hash={facet} />
      <Select
        value={selected}
        options={select_options()}
        isMulti={true}
        placeholder={
          sorted_options_by_numbers
            .slice(0, 3)
            .map((o) => o.title)
            .join(", ") + "…"
        }
        className={"react-select-container " + classess}
        classNamePrefix="react-select"
        onChange={(value, action) => {
          switch (action.action) {
            case "select-option":
            case "remove-value":
              on_select(
                facet,
                value.map(({ value }) => value),
              );
              break;
            case "clear":
              on_select(facet, []);
              break;
            default:
              throw new Error("invalid action");
          }
        }}
      />
    </div>
  );
}

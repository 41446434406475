// conf
import { conf } from "conf/plone";
// hooks
import { useDocumentTitle } from "usehooks-ts";
// helpers
import { clean_raw_html, is_object, is_string, local_date } from "helpers/helpers";

function Text({ str }) {
  if (is_string(str)) return <div>${str}</div>;
  if (is_object(str) && str.data)
    return (
      <div dangerouslySetInnerHTML={{ __html: clean_raw_html(str.data) }} />
    );
}

export default function PressItemFull({ data }) {
  const { title, description, text, release_date } = data;
  const date = new Date(release_date);
  useDocumentTitle(`${title} | ${conf.site_title}`);

  return (
    <div>
       <div className="container">
        <strong>{local_date(date)}</strong>
        <h2>{title}</h2>
        <p className="lead">{description}</p>
        <hr />
       </div>
       {data?.image?.scales?.large?.download && (
       <div className="container">
         <img  src={data.image.scales.large.download} />
       </div>
       )}
       <div className="container">
        <Text str={text} />
       </div>
    </div>
  );
}
